<template>
  <div>
    <div class="card">
      <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
        <div class="anan-tabs__nav">
          <div
            class="anan-tabs__nav-warp px-2"
            style="margin-top: 5px;"
          >
            <div
              class="anan-tabs__nav-tabs"
              style="transform: translateX(0px)"
            >
              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'all' }"
                style="white-space: normal"
                @click="GetOrderAll(status = 0,search_val = null), isActive = 'all'"
              >
                <span> {{ $t('all') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'cancel' }"
                style="white-space: normal"
                @click="GetOrderAll(status = 1,search_val = null), isActive = 'cancel'"
              >
                <span> {{ $t('cancel') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'waiting' }"
                style="white-space: normal"
                @click="GetOrderAll(status = 2,search_val = null), isActive = 'waiting'"
              >
                <span> {{ $t('pendingReview') }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-lg-flex justify-content-between align-items-center px-2 mt-2">
        <h3 class="font-weight-bolder">
          {{ $t('transferToChina') }}
        </h3>

        <div class="d-flex align-items-center">
          <div class="anan-input__inner anan-input__inner--normal">
            <input
              v-model="search_val"
              type="text"
              :placeholder="$t('search')"
              class="anan-input__input"
              @keyup.enter="GetOrderAll(status)"
            >
            <div class="anan-input__suffix">
              <button
                type="button"
                class="anan-button anan-button--normal anan-button--primary"
                @click="GetOrderAll(status)"
              >
                <i class="fal fa-search" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <div class="p-2">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  label="กำลังโหลด..."
                  variant="danger"
                />
              </div>
            </template>

            <template #empty>
              <div class="text-center">
                <img
                  src="@/assets/images/anan-img/empty/empty.png"
                  alt="empty"
                  width="180px"
                >
                <p>No Data</p>
              </div>
            </template>

            <template #cell(itemnumber_text)="data">
              <b-link :to="{name: 'admin-transfer-check', params: {id: data.item._id}}">
                {{ data.item.itemnumber_text }}
              </b-link>
            </template>

            <template #cell(detail)="data">
              {{ $t('key-55') }} {{ data.item.name_bank }} <br>
              {{ $t('accountName') }} {{ data.item.name_account }}
            </template>

            <template #cell(created_at)="data">
              {{ time(data.item.created_at) }}
            </template>
            <template #cell(approvalDate)="data">
              {{ data.item.date_approve ? time(data.item.date_approve): '-' }}
            </template>
            <template #cell(customer)="data">
              <b-link :to="{name: 'admin-employee-edit', params: {id: data.item.uid}}">
                {{ data.item.username ? data.item.username: '-' }}
              </b-link>
            </template>

            <template #cell(amount)="data">
              ¥ {{ Commas(data.item.amount) }}
            </template>

            <template #cell(proofOfTransaction)="data">
              <img
                v-if="data.item.fileadmin"
                :src="GetImg('transfer_file',data.item.fileadmin)"
                alt="img-document"
                class="border"
                height="40"
                @click="showPicture(data.item.fileadmin)"
              >
              <span v-else>-</span>

              <vue-image-lightbox-carousel
                ref="lightbox"
                :show="showLightbox"
                :images="images"
                :show-caption="false"
                @close="closeBox()"
              />
            </template>

            <template #cell(status)="data">
              <span
                v-if="data.item.status === 1"
                class="badge badge-primary badge-pill"
              > {{ $t('pendingReview') }}
              </span>

              <span
                v-if="data.item.status === 2"
                class="badge badge-success badge-pill"
              > {{ $t('operationCompleted') }}
              </span>

              <span
                v-if="data.item.status === 0"
                class="badge badge-secondary badge-pill"
              > {{ $t('cancelled') }} </span>
            </template>

            <template #cell(action)="data">
              <feather-icon
                icon="Edit3Icon"
                class="mr-1 text-primary cursor-pointer"
                @click="$router.push({ name: 'admin-transfer-check', params: { id: data.item._id } })"
              />
            </template>
          </b-table>

          <div class="demo-spacing-0 d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="GetOrderAll(status,search_val)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BPagination, BLink, BTable, BSpinner, BOverlay,
} from 'bootstrap-vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BPagination,
    BLink,
    BTable,
    BSpinner,
    BOverlay,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      search: '',
      isActive: 'all',
      selectedRadio: 'active',
      optionsRadio: [
        { text: 'All', value: 'all' },
        { text: 'Car', value: 'car' },
        { text: 'Ship', value: 'ship' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      items: [],
      pic: null,

      total: 0,
      notpaid: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      status: 0,
      showOver: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'itemnumber_text', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'created_at', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'approvalDate', label: this.$t('approvalDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'customer', label: this.$t('customer'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'detail', label: this.$t('details'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'proofOfTransaction', label: this.$t('transactionEvidence'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount', label: this.$t('quantity'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'status', label: this.$t('status'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  watch: {
    '$route.query.querykey': {
      immediate: true,
      handler() {
        this.handleQueryChange()
      },
    },
  },
  mounted() {
    this.handleQueryChange()
    // this.GetOrderAll(this.status)
  },
  methods: {
    handleQueryChange() {
      const { querykey } = this.$route.query
      if (querykey) {
        if (Number(querykey) === 2) {
          this.isActive = 'waiting'
          this.GetOrderAll(this.status = 2)
        }
      } else {
        this.GetOrderAll(this.status)
      }
    },
    // Commas(num) {
    //   return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // },
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    GetNotify() {
      this.$http
        .get('/order/Notify')
        .then(response => {
          // console.log(response.data)
          this.total = response.data.total
          this.waitingforcheck = response.data.waitingforcheck
          this.waitingforpayment = response.data.waitingforpayment
          this.paid = response.data.paid
          this.OrderSuccess = response.data.OrderSuccess
          this.completetrack = response.data.completetrack
          this.completewarehouse = response.data.completewarehouse
          this.cancel = response.data.cancel
        })
        .catch(error => console.log(error))
    },
    GetOrderAll(type) {
      this.showOver = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        type,
        search_val: this.search_val,
      }
      this.$http
        .get('/BankChina/getDataAll', { params })
        .then(response => {
          // response.data.data.map(ele => { ele.fileadmin = null })
          this.items = response.data.data
          // console.log(this.items)
          // this.items = response.data.data
          // this.totalRows = this.items.length
          this.totalRows = response.data.total
          this.showOver = false
          // this.ShowIMG()
        })
        .catch(error => {
          console.log(error)

          this.showOver = false
        })
    },
    ShowIMG() {
      const Id = this.items.map(ele => ele._id)
      const params = {
        id: Id,
      }
      this.$http.get('/BankChina/GetimgAdmin', { params })
        .then(response => {
          // console.log(response)
          response.data.forEach((data, index) => {
            this.items[index].fileadmin = data.fileadmin
          })
        })
        .catch(err => console.log(err))
    },
    // BankChina/GetimgAdmin
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    updateKeyword(id, note) {
      // console.log('Item ID:', id)
      // console.log('note:', note)
      const params = {
        id,
        note,
      }
      // console.log(params)
      this.$http
        .post('/order/updateNote', params)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('transfer_file', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
  },
}
</script>

      <style lang="scss" scoped></style>
